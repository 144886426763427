@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --shopfood-1: #AB274F;
  --shopfood-2: #f25f5c;
  --shopfood-3: #f3c44c;
  --shopfood-4: #247ba0;
  --shopfood-5: #70c1b3;
  --shopfood-6: #a9479d;
  --shopfood-7: #8bc37b;
  --shopfood-success: #8bc37b;
  --shopfood-error: #f87171;
  --shopfood-notify: #ef4444;
  --shopfood-info: #57a1c0;
  --shopfood-danger: #f87171;
  --shopfood-alert: #fb923c;

  --gray-100: rgb(243 244 246);
  --gray-300: rgb(209 213 219);
  --gray-400: rgb(156 163 175);
  --gray-500: rgb(107 114 128);
  --gray-600: rgb(75 85 99);
  --gray-700: rgb(55 65 81);
  --gray-800: rgb(31 41 55);
  --gray-900: rgb(17 24 39);

  --color-customPrimary: #475569;
  --color-customPrimaryHover: #6b7280;
  --color-customSecondary: #6b7280;
  --color-customSecondaryHover: #94a3b8;
  --color-customTopbar: #f9fafb;
  --color-customTopbarText: #475569;
  --color-customSearch: rgba(243, 244, 246, 0.5);
  --color-customHeader: #FFFFFF;
  --color-customHeaderText: #475569;
  --color-customFooter: #f1f5f9;
  --color-customFooterText: #475569;
  --color-customFooterCart: #f1f5f9;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothigrng: grayscale;
  color: var(--gray-600);
}
h3, h2 {
  line-height: 1.125em !important;
}
* {
  letter-spacing: -.016rem;
  outline: none;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after, .swiper-button-prev:after, .swiper-button-next:after {
  color: var(--gray-600);
}
.swiper-button-next, .swiper-button-prev {
  transition: background-color 400ms ease !important;
  padding: 1em;
}
.swiper-button-prev {margin-left: -14px;}
.swiper-button-next {margin-right: -14px;}
/* .swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: #ffffff30;
  border-radius: 10px;
} */

.swiperMenuCategories *:after {
  font-size: 12px !important;
}

.swiper-button-next:after {margin-right: 3em;}
.swiper-button-prev:after {margin-left: 3em;}
.swiperNavigation *:after {
  /* border: 1px solid var(--gray-300); */
  color: #FFF;
  border-radius: 100%;
  font-size: 18px !important;
  min-width: 38px !important;
  min-height: 38px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .6);
  transition: all 300ms ease !important;
}
.swiper-button-next:hover:after, .swiper-button-prev:hover:after {
  background-color: var(--color-custom-primary);
  /* box-shadow: 0 0 10px #FFF; */
}


.swiperBullets .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  opacity: 1;
  background: rgba(39, 45, 55, 0.3);
}
@media screen and (min-width: 768px) {
  .swiperBullets .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
  }
  ::-webkit-scrollbar {width: 10px; padding: 3px;}
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {background: var(--gray-300) !important; border-radius: 10px;}
  ::-webkit-scrollbar-thumb:hover { background: var(--gray-400) !important}

}

.swiperBullets .swiper-pagination-bullet-active {
  background: rgba(39, 45, 55, 0.8);
}




a {
    transition: all 300ms ease !important;
}


input[type="radio"], input[type=checkbox] {
  all: unset;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 2px solid var(--gray-500);
  outline: none !important;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -ms-transition: all .3s;
  transition: all .3s;
}

input[type="radio"]:disabled {
  border-color: var(--gray-300);
  background-color: var(--gray-100);
}

input[type="radio"]:hover:not(:disabled) {
  border-color: var(--color-custom-primary);
}

input[type="radio"]:checked {
  border-color: var(--color-custom-primary);
  background-color: var(--color-custom-primary);
}

input[type="radio"]:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background-color: transparent;
  /* -webkit-transition: background-color .5s;
  -moz-transition: background-color .5s;
  -o-transition: background-color .5s;
  -ms-transition: background-color .5s;
  transition: background-color .5s */
}


input[type="radio"]:checked::before {
  background-color: var(--color-custom-primary);
}




/* input[type=checkbox] {
  border-radius: 30%;
  -moz-border-radius: 30%;
  -webkit-border-radius: 30%;
} */
input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  margin: 0 2px 0 3px
}


/* Estilo para a animação de fade-in */
.fadeInIcon {
  opacity: 0;
  animation: fadeIn 0.65s ease-in-out forwards;
}

/* Definindo a animação */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.zoomInIcon, .zoomInIconNoDelay, .zoomInIconDelay {
  opacity: 0;
  transform: scale(0); /* Começa com o ícone em 0 (invisível) */
  animation: zoomIn 0.4s ease-in-out forwards;
  animation-delay: 0.35s;
}
.zoomInIconNoDelay {animation-delay: 0s;}
.zoomInIconDelay {animation-delay: 0.7s;}

/* Definindo a animação */
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(2); /* Início do zoom pequeno */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Fim do zoom no tamanho normal */
  }
}

.zoomOutIcon, .zoomOutIconNoDelay, .zoomOutIconDelay {
  opacity: 0;
  transform: scale(1); /* Começa com o ícone em 0 (invisível) */
  animation: zoomOut 0.4s ease-in-out forwards;
  animation-delay: 0.35s;
}
.zoomOutIconNoDelay {animation-delay: 0s;}
.zoomOutIconDelay {animation-delay: 0.7s;}

/* Definindo a animação */
@keyframes zoomOut {
  0% {
    opacity: 2;
    transform: scale(1); /* Início do zoom pequeno */
  }
  100% {
    opacity: 0;
    transform: scale(0); /* Fim do zoom no tamanho normal */
  }
}


.animate-side {
  transform: scale(0); /* Começa com o ícone em 0 (invisível) */
  animation: sideAnimate 0.6s ease-in-out infinite;
}
@keyframes sideAnimate {
  0%, 100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}


.animate-drag-left {
  transform: scale(0);
  animation: sideAnimateLeft 1.15s ease-in-out infinite;
}
@keyframes sideAnimateLeft {
  0%, 100% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  30% {
    transform: translateX(-40%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-drag-right {
  transform: scale(0);
  animation: sideAnimateRight 1.15s ease-in-out infinite;
}
@keyframes sideAnimateRight {
  0%, 100% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  30% {
    transform: translateX(40%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}